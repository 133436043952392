import PropTypes from 'prop-types';
import Image from 'next/image';
import Heading from '@/atoms/Heading';
import Avatar from '@/atoms/Avatar';
import Text from '@/atoms/Text';
import Icon from '@/atoms/Icon';
import Link from 'next/link';

const BlogCard = ({
    blogImg,
    blogTitle,
    profileImg,
    profileName,
    profileDesignation,
    time,
    blogType,
    vertical,
    className = '',
    classForFooter = '',
    blogId,
    imageWidth,
    imageHeight,
}) => {
    const myLoader = ({ src }) => src;
    return (
        <div
            className={`group bg-grey-shade6 w-full rounded-xl border-grey-shade5 overflow-hidden ${className} ${
                vertical ? 'md:flex' : ''
            }`}
        >
            <Link
                className={` ${className} ${vertical ? 'md:flex w-full' : ''}`}
                href={`/explore-details?id=${blogId}`}
            >
                <div className={`relative ${vertical && ''}`}>
                    {blogImg && (
                        <Image
                            loader={myLoader}
                            src={blogImg}
                            width={imageWidth ?? 480}
                            height={imageHeight ?? 270}
                            alt="blog image"
                            className={`object-cover ${
                                vertical
                                    ? 'md:rounded-tl-lg md:rounded-bl-lg h-[195px] w-[388px]'
                                    : `w-full rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-none  ${imageHeight === 214 ? 'h-[214px]' : 'h-[270px]'}`
                            }`}
                            objectFit="contain"
                        />
                    )}
                    <div className="absolute left-0 bottom-0 p-4">
                        <Icon
                            src={`/images/icons/${
                                blogType === 'video'
                                    ? 'icon_video.svg'
                                    : 'icon_document.svg'
                            }`}
                            alt="Icon"
                        />
                    </div>
                </div>

                <div
                    className={`py-5 px-4 ${classForFooter} ${
                        vertical &&
                        'md:flex md:flex-col md:justify-between md:w-[calc(100%-388px)]'
                    }`}
                >
                    <Heading
                        type="h4"
                        fontFamily="font-body"
                        fontWeight="font-medium"
                        fontColor="text-grey-shade2"
                        className="line-clamp-2 h-[56px]"
                    >
                        {blogTitle}
                    </Heading>
                    <div className="flex justify-between items-center mt-4">
                        <div className="flex items-center gap-2">
                            <Avatar imgSrc={profileImg} size="medium" />
                            <div className="text-grey-shade1">
                                <Text
                                    variant="caption"
                                    fontWeight="font-medium"
                                >
                                    {profileName}
                                </Text>
                                <Text
                                    variant="captionSmall"
                                    fontWeight="font-medium"
                                    className="text-grey-shade3"
                                >
                                    {profileDesignation}
                                </Text>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Icon
                                src="/images/icons/icon_clock.svg"
                                width={20}
                                height={20}
                                alt="Icon"
                            />
                            <span className="ml-1 text-[10px] text-grey-shade3">
                                {time}
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

BlogCard.defaultProps = {
    blogImg: '/images/care_plan_1.jpg',
    blogTitle: "What causes psoriasis and what doesn't?",
    profileImg: '/images/profile_1.svg',
    profileName: 'Priya Dharmapurikar',
    profileDesignation: 'Yoga Expert',
    time: '60 minutes',
    blogType: 'video',
    vertical: false,
};

BlogCard.propTypes = {
    blogImg: PropTypes.string,
    blogTitle: PropTypes.string,
    profileImg: PropTypes.string,
    profileName: PropTypes.string,
    profileDesignation: PropTypes.string,
    time: PropTypes.string,
    blogType: PropTypes.string,
    vertical: PropTypes.bool,
    className: PropTypes.string,
    classForFooter: PropTypes.string,
    blogId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
};

export default BlogCard;
