import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BlogCard from './BlogCard';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import Button from '@/atoms/Button';
import SectionHeading from '@/atoms/SectionHeading';
import Text from '@/atoms/Text';

const ExploreSection = ({
    blogContent,
    withSlider = true,
    heading = 'Learn more about caring for psoriasis',
    subHeading = 'Find expert expert tips, advice, and stories of successful psoriasis management',
    showSubHeading = true,
    className,
    categories,
    tabSpace = 'mt-6 mb-8 -mx-2',
}) => {
    const [selectedTab, setSelectedTab] = useState('Care Plan');

    const filteredContent =
        selectedTab === 'all'
            ? blogContent
            : blogContent.filter((post) => {
                  return post?.category?.find((p) => p.name === selectedTab);
              });

    return (
        <div
            id="explore"
            className={`container overflow-hidden ${className || ''}`}
        >
            <div data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                <SectionHeading
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                    align="center"
                >
                    {heading}
                </SectionHeading>
            </div>

            {showSubHeading && (
                <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-once="true"
                >
                    <Text
                        variant="body3"
                        className="text-center"
                        fontWeight="font-medium"
                    >
                        {subHeading}
                    </Text>
                </div>
            )}
            <div
                className={`flex flex-wrap gap-2 justify-center ${tabSpace}`}
                data-aos="fade-up"
                data-aos-once="true"
            >
                {categories.map((category) => (
                    <Button
                        key={category.name}
                        style={
                            selectedTab === category.name
                                ? 'secondary'
                                : 'ghost'
                        }
                        size="medium"
                        onClick={() => setSelectedTab(category.name)}
                        btnClass="capitalize"
                        label={category.name}
                    />
                ))}
            </div>
            {withSlider ? (
                <Slider
                    spaceBetween={20}
                    loop={false}
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2.1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                    }}
                    style={{
                        paddingBottom: '70px',
                    }}
                >
                    {filteredContent.map((post, index) => (
                        <SwiperSlide key={post.id}>
                            <div
                                data-aos="fade-left"
                                data-aos-delay={index * 100}
                                data-aos-once="true"
                            >
                                <BlogCard
                                    blogImg={post.blogImg}
                                    blogTitle={post.blogTitle}
                                    profileImg={post.profileImg}
                                    profileName={post.profileName}
                                    profileDesignation={post.profileDesignation}
                                    time={post.time}
                                    blogType={post.blogType}
                                    blogId={post.blogId}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            ) : (
                <div className="grid grid-cols-3 gap-5">
                    {filteredContent.slice(0, 3).map((post, index) => (
                        <BlogCard
                            key={index}
                            blogImg={post.blogImg}
                            blogTitle={post.blogTitle}
                            profileImg={post.profileImg}
                            profileName={post.profileName}
                            profileDesignation={post.profileDesignation}
                            time={post.time}
                            blogType={post.blogType}
                            blogId={post.blogId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

ExploreSection.propTypes = {
    blogContent: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            blogImg: PropTypes.string.isRequired,
            blogTitle: PropTypes.string.isRequired,
            profileImg: PropTypes.string.isRequired,
            profileName: PropTypes.string.isRequired,
            profileDesignation: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
            blogType: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
        })
    ).isRequired,
    withSlider: PropTypes.bool,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    showSubHeading: PropTypes.bool,
    className: PropTypes.string,
    categories: PropTypes.array,
    defaultCategory: PropTypes.string,
    tabSpace: PropTypes.string,
};

export default ExploreSection;
